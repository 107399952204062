<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    permanent
    left
    width="200"
    color="#F9F9F9"
    floating
  >
    <v-app-bar color="#F9F9F9" dense outlined light elevation="0" class="mt-3">
      <img class="brand_logo" src="@/assets/aprotime-logo.svg" />
      <p class="brand_text">Aprotime</p>
    </v-app-bar>
    <!-- <v-divider></v-divider> -->
    <div class="scrollable-headers my-8 custom-scrollbar">
      <v-list v-if="userDetails.roles.has_all_access" subheader flat nav dense>
        <v-list-item-group
          v-model="selectedItem"
          active-class="link"
          color="#000"
        >
          <div v-for="(header, index) in filteredLinks" :key="index">
            <v-subheader
              @click="toggleHeader(header.index)"
              class="category_text flex items-center"
              color="black"
            >
              <div class="flex items-center space-x-2">
                <v-icon
                  :style="{
                    color: 'black',
                    borderRadius: '50%',
                  }"
                >
                  {{
                    header.header === "Time Tracking"
                      ? "mdi-clock"
                      : header.header === "Payments"
                      ? "mdi-invoice-text"
                      : header.header === "Management"
                      ? "mdi-cog"
                      : "mdi-note-text"
                  }}
                </v-icon>
                <span class="header-text">{{ header.header }}</span>
              </div>
              <div>
                <v-icon size="20" color="black">
                  {{
                    isHeaderOpen(header.index)
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </div>
            </v-subheader>
            <template v-if="isHeaderOpen(header.index)">
              <v-list-item
                v-for="item in header.items"
                :key="item.title"
                router
                :to="item.route"
                :class="[
                  'flex pl-12 text-sm',
                  { 'font-semibold': $route.path === item.route },
                ]"
                :color="$route.path === item.route ? 'primary' : 'black'"
              >
                {{ item.title }}
              </v-list-item>
            </template>
          </div>
        </v-list-item-group>
      </v-list>
      <v-list v-else subheader flat nav dense>
        <v-list-item-group
          v-model="selectedItem"
          active-class="link"
          color="#000"
        >
          <div v-for="(header, index) in filteredLinks" :key="index">
            <v-subheader
              @click="toggleHeader(header.index)"
              class="category_text d-flex justify-space-between"
              color="black"
            >
              <div class="d-flex align-center">
                <v-icon
                  class="me-2"
                  :style="{
                    color: 'black',
                    borderRadius: '50%',
                  }"
                >
                  {{
                    header.header === "Time Tracking"
                      ? "mdi-clock"
                      : header.header === "Payments"
                      ? "mdi-invoice-text"
                      : header.header === "Management"
                      ? "mdi-cog"
                      : "mdi-note-text"
                  }}
                </v-icon>

                <span class="text-black">{{ header.header }}</span>
              </div>

              <v-icon color="black">
                {{
                  isHeaderOpen(header.index)
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}
              </v-icon>
            </v-subheader>
            <template v-if="isHeaderOpen(header.index)">
              <v-list-item
                v-for="item in header.items"
                :key="item.title"
                router
                :to="item.route"
              >
                <v-list-item-content>
                  <v-list-item-title class="nav_text title-with-margin">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
        </v-list-item-group>
      </v-list>
    </div>

    <v-footer color="#F9F9F9" padless class="px-10">
      <v-row class="">
        <span class="me-2 nav_text">
          {{ userDetails.first_name }} {{ userDetails.last_name }}
        </span>
        <div>
          <img
            v-if="userDetails?.logo"
            :src="userDetails?.logo"
            alt="User Avatar"
            class="rounded"
            style="width: 30px; height: 30px; object-fit: cover"
          />
          <v-avatar v-else rounded size="30" color="#D9D9D9">
            <v-icon size="20" color="#8C8C8C" dark>mdi-account</v-icon>
          </v-avatar>
        </div>

        <p
          :style="{
            color: 'black',
            fontSize: '12px',
          }"
        >
          Version 0.01
        </p>
      </v-row>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideNav",

  data: () => ({
    drawer: true,
    selectedItem: 0,
    openHeaders: [],

    links: [
      { header: "Time Tracking" },
      {
        title: "Time Entry",
        icon: "mdi-view-dashboard-outline",
        route: "/auth/time-entry",
      },
      {
        title: "Calendar",
        icon: "mdi-list-box-outline",
        route: "/auth/calendar",
      },
      { header: "Payments" },
      {
        title: "Invoices",
        icon: "mdi-receipt-text-outline",
        route: "/auth/invoices",
      },
      { header: "Entities" },
      {
        title: "Client Project",
        icon: "mdi-account-outline",
        route: "/auth/clients",
      },
      {
        title: "Employee and Ranks",
        icon: "mdi-account-multiple-outline",
        route: "/auth/employees",
      },
      {
        title: "Office Department",
        icon: "mdi-alpha-d-circle-outline",
        route: "/auth/departments",
      },
      {
        title: "Activities",
        icon: "mdi-playlist-check",
        route: "/auth/activity",
      },
      {
        title: "Service Terms",
        icon: "mdi-playlist-check",
        route: "/auth/service-terms",
      },
      { header: "Management" },
      { title: "Settings", icon: "mdi-cog-outline", route: "/auth/settings" },
    ],
  }),

  computed: {
    userDetails() {
      return this.$store.state.Auth.user;
    },

    filteredLinks() {
      const headers = [];
      let currentHeader = null;
      this.links.forEach((item, index) => {
        if (item.header) {
          currentHeader = { header: item.header, index, items: [] };
          headers.push(currentHeader);
        } else if (currentHeader) {
          currentHeader.items.push(item);
        }
      });

      return headers;
    },
  },

  methods: {
    toggleHeader(index) {
      if (this.openHeaders.includes(index)) {
        this.openHeaders = this.openHeaders.filter((i) => i !== index);
      } else {
        this.openHeaders.push(index);
      }
    },
    isHeaderOpen(index) {
      return this.openHeaders.includes(index);
    },
  },
};
</script>

<style scoped>
.category_text {
  margin-top: 10px;
  cursor: pointer;
  color: black;
  font-size: 14px;
  width: 100%;
}
.brand_logo {
  width: 26px;
}
.brand_text {
  font-size: 18px;
  font-weight: 700;
  color: black;
  margin-left: 5px;
  margin-top: 17px;
}
.v-footer {
  width: 100%;
  position: absolute;
  bottom: 30px;
  left: 0;
}
.v-avatar {
  margin-top: -4px;
}
.scrollable-headers {
  overflow-y: auto;
  height: calc(100vh - 180px);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
  border: 3px solid transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}
</style>
