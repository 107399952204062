<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="h-screen flex items-center">
          <v-card class="mx-auto" max-width="400" flat>
            <div class="text-center">
              <div class="flex flex-col items-center mb-2">
                <img src="@/assets/aprotime-logo.svg" />
                <p class="text-2xl font-bold text-black mt-1">Aprotime</p>
              </div>
              <p class="font-medium text-base text-[#585f6c]">
                A pro system to take control of your time entry and achive your
                productivity and financial goal with ease.
              </p>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    filled
                    rounded
                    class="font-medium text-base text-[#585f6c]"
                    background-color="#F3F3F3"
                    color="#333333"
                    :rules="emailRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    label="Password"
                    hint="At least 8 characters"
                    class="input-group--focused"
                    filled
                    single-line
                    rounded
                    background-color="#F3F3F3"
                    color="#333333"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="#CE0000"
                    class="mb-4 -mt-6"
                    elevation="0"
                    x-large
                    block
                    rounded
                    dark
                    :loading="loading"
                    @click="login"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <div class="flex w-full justify-end">
              <router-link to="/forgot-password">
                <p class="uppercase mb-4 font-medium self-end text-black">
                  Forgot Password?
                </p></router-link
              >
            </div>

            <div class="flex items-center space-x-4 font-medium">
              <p>Don’t have an account?</p>
              <router-link to="/subscribe">
                <p class="text-primary capitalize">
                  Register Here
                </p></router-link
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventBus from "@/common/EventBus";

export default {
  name: "LoginPage",

  components: {},

  data: () => ({
    valid: true,
    show: false,
    loading: false,
    email: "",
    password: "",
    rules: {
      required: (value) => !!value || "Required",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  methods: {
    confirmPayment(sessionId) {
      this.$store
        .dispatch("Auth/confirmSubscription", { sessionId})
        .then(() => {
          EventBus.dispatch("show-snackbar", {
            color: "success",
            message: "Payment confirmed successfully!",
          });
        })
        .catch((error) => {
          EventBus.dispatch("show-snackbar", {
            color: "error",
            message: "Payment confirmation failed.",
          });
          console.error("Error confirming payment:", error);
        });
    },
    login() {
      let formstatus = this.$refs.form.validate();
      if (formstatus != true) return;
      this.loading = true;
      this.$store
        .dispatch("Auth/login", {
          email: this.email,
          password: this.password,
        })
        .catch((error) => {
          if (error.response.data.error == "Organisation is not verified") {
            this.$router.push({
              name: "Verification",
              params: {
                email: this.email,
              },
            });
          }
          EventBus.dispatch("show-snackbar", {
            color: "error",
            message: error.response.data.error,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    const sessionId = this.$route.query.session_id;
    if (sessionId) {
      this.confirmPayment(sessionId);
    }
  },
};
</script>
