// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  invoices: [],
  invoicedata: {},
};

const getters = {};

const actions = {
  getAllInvoices(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/invoice/all", {
          params: {
            search: data.search,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_invoices", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSearchInvoices(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invoice/all?search=${data}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("set_invoices", response.data.data);
          context.commit("set_count", response.data.count);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getInvoiceResources(context, matterId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invoice/resources/${matterId}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/invoice/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createBulkInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/invoice/bulk-upload/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  markedPaidInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/invoice/mark_paid/${data.id}`, data.paidStatus, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancelInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/invoice/mark_cancelled/${data.id}`, data.cancelledStatus, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_invoice_obj(state, data) {
    state.entries.push(data);
  },
  set_invoices(state, data) {
    state.invoices = data;
  },
  set_data(state, data) {
    state.invoicedata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
