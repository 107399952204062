// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";
import EventBus from "@/common/EventBus";

const state = {
  activities: [],
  activitydata: {},
};

const getters = {};

const actions = {
  createActivity(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/activity/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_activity_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteActivity(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/activity/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateActivity(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/activity/${data.id}`,
          {
            code: data.code,
            name: data.title,
            status: data.status,
          },
          {
            headers: {
              ...authHeaders(localStorage.getItem("access_token")),
            },
          }
        )
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getActivities(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/activity/all", {
          params: {
            search: data.search,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_activities", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_activity_obj(state, data) {
    state.activities.push(data);
  },
  set_activities(state, data) {
    state.activities = data;
  },
  set_data(state, data) {
    state.activitydata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
