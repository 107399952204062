// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  ranks: [],
  rankdata: {},
};

const getters = {};

const actions = {
  createRank(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/rank/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_rank_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteRank(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/rank/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateRank(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/rank/${data.id}`, data.rank, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllRank(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/rank/all", {
          params: {
            search: data.search,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_ranks", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_rank_obj(state, data) {
    state.ranks.push(data);
  },
  set_ranks(state, data) {
    state.ranks = data;
  },
  set_data(state, data) {
    state.rankdata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
