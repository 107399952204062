// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  offices: [],
  officedata: {},
};

const getters = {};

const actions = {
  createOffice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/office/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_office_obj", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateOffice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/office/${data.id}`, data.office, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteOffice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/office/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllOffices(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/office/all", {
          params: {
            search: data.search,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_offices", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_office_obj(state, data) {
    state.offices.push(data);
  },
  set_offices(state, data) {
    state.offices = data;
  },
  set_data(state, data) {
    state.officedata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
